<template>
  <div>
    <heade></heade>
    <div
      class="com"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="com_box">
        <div>
          <div class="com-left">
            <div class="com-left-top">{{ practice.name }}</div>
            <div class="com-left-com">
              <div class="com-left-com-item">主题总论</div>
            </div>
            <div class="com-left-foot">
              <div
                v-for="(item, index) in practice.division_category"
                :key="index"
                class="nice-scroll"
              >
                <div
                  class="navgatorLi"
                  v-for="(items, indexs) in item.division"
                  :key="indexs"
                >
                  <a
                    href="javascript:;"
                    class="com-left-foot-but"
                    @click="
                      goPracticeDetatil(
                        items.division_content_id,
                        items.charge_mode,
                        indexs,
                        items.division_id,
                        items.jump_link_url
                      )
                    "
                    >{{ items.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="com-right">
            <el-button
              type="text"
              icon="el-icon-arrow-left"
              @click="$router.back(-1)"
              >返回上一页</el-button
            >
            <div class="nice-scroll" v-html="practice.content"></div>
          </div>
        </div>
        <div class="com-btt">
          <div>
            <div>
              <router-link
                v-for="(item, index) in practiceTheme"
                :key="index"
                :to="{
                  path: path,
                  query: { id: item.id },
                }"
                class="com-bbt-item"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import $store from "../../store";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { scroll } from "@utils/scroll";
import {
  getencryptionPracticeCourse,
  practiceCoursesByModularId,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    practice() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  props: {},
  data() {
    return {
      practice: [],
      queryWhere: {},
      modularQueryWhere: {},
      practiceTheme: [],
      content: "",
      backgroud: "",
      detailPath: "",
      path: "",
    };
  },
  mounted: function () {
    let that = this;
    getencryptionPracticeCourse(that.queryWhere).then(function (res) {
      that.$set(that, "practice", res.data);
      scroll($(".nice-scroll"));
    });

    practiceCoursesByModularId(that.modularQueryWhere).then(function (res) {
      that.$set(that, "practiceTheme", res.data);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.modularQueryWhere.modular_id = this.$route.meta.id;
    this.detailPath = this.$route.meta.detailPath;
    this.path = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  methods: {
    goPracticeDetatil: function (
      id,
      charge_mode,
      index,
      division,
      jump_link_url
    ) {
      if (
        charge_mode == 1 ||
        ($store.state.app.userInfo != null &&
          $store.state.app.userInfo.is_empower)
      ) {
        if (jump_link_url != null) {
          window.location.href = jump_link_url;
        } else {
          window.location.href =
            this.detailPath + "?id=" + id + "&division=" + division;
        }
      } else {
        if (!$store.state.app.token) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content: "您还未登录！是否去登录？",
              btn: ["是", "否"],
              closeBtn: 0,
              skin: "layui-layer-molv",
              yes: function () {
                window.location.href = "/login";
              },
            });
          });
        } else if (!$store.state.app.userInfo.is_empower) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content:
                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
              closeBtn: 0,
              btn: ["课题申报", "关闭"],
              success: function (layero) {
                var btn = layero.find(".layui-layer-btn");
                btn.find(".layui-layer-btn0").attr({
                  href: "/application/shenbaobiao",
                });
              },
            });
          });
        }
      }
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.com_box {
  width: 1484px;
  height: 1660px;
  margin: 0 auto;
  position: relative;
}

.com-left-com {
  margin-bottom: 0px !important;
}
.com-left-com-item {
  width: 100%;
  margin-top: 15px;

  cursor: pointer;
}
.com-btt {
  width: 911px;
  font-size: 14px;
}
.com-btt > div > div {
  width: 640px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 193px;
}
.com-left-foot {
  height: 500px;
  overflow-y: auto;
}
/* 隐藏滚动条 */
.com-left-foot::-webkit-scrollbar {
  display: none;
}
.com-bbt-item {
  width: 15px;
  font-size: 18px;
}
.el-button--text {
  color: rgb(121, 0, 0);
}
.nice-scroll {
  height: 95% !important;
}
</style>
