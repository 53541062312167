import { render, staticRenderFns } from "./enChuanTheme.vue?vue&type=template&id=35ac7b70&scoped=true"
import script from "./enChuanTheme.vue?vue&type=script&lang=js"
export * from "./enChuanTheme.vue?vue&type=script&lang=js"
import style0 from "./enChuanTheme.vue?vue&type=style&index=0&id=35ac7b70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ac7b70",
  null
  
)

export default component.exports